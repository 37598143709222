import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../../../Icon';
import { AngleDownIcon, CloseIconChip } from '../../../../icons';
import { palette } from '../../../../styles/_palette';

const NewChips = ({
  id,
  icon,
  text,
  type,
  name,
  stat,
  value,
  style,
  dirty,
  onClick,
  iconSize = 20,
  storedValue,
  borderRadius = '100px',
  resetHandler,
  cypress,
  isDark,
  iconType = 'close',
  useDirtyAsActive,
  color,
  iconActiveColor,
  iconNeedColorChange,
  activeIcon,
  isTextPadding = true
}) => {
  const isActive =
    (useDirtyAsActive && dirty) ||
    (type === 'checkbox'
      ? storedValue?.includes(value)
      : // eslint-disable-next-line
        value !== undefined && value === storedValue);

  const labelClasses = cn('chips-container', {
    'chips-active': isActive,
    'chips-active_dark': isActive && isDark
  });

  const iconColor =
    iconActiveColor && isActive ? iconActiveColor : color || '#fff';

  return (
    <label
      id={id}
      data-cy={cypress}
      className={labelClasses}
      style={{ borderRadius, ...style }}
    >
      <div
        tabIndex={0}
        role="button"
        data-stat={stat}
        onClick={onClick}
        onKeyPress={onClick}
        className={cn('chips__content', {
          chips__box: icon
        })}
      >
        {type !== 'button' && (
          <Field
            name={name}
            component="input"
            type={type}
            className="display-none"
            value={value === undefined ? '' : value}
          />
        )}
        {icon && (
          <Icon
            IconComponent={isActive && activeIcon ? activeIcon : icon}
            width={iconSize}
            height={iconSize}
            color={iconColor}
            iconNeedColorChange={iconNeedColorChange}
          />
        )}
        <span
          className={cn('chips__text', {
            'chips__text-padding': isTextPadding
          })}
        >
          {text}
        </span>
      </div>

      {iconType === 'angle' && (
        <div
          tabIndex={0}
          role="button"
          className="chips__reset"
          onClick={onClick}
          onKeyPress={onClick}
        >
          <AngleDownIcon fill={dirty ? palette.white : palette.cocoaBrown} />
        </div>
      )}

      {resetHandler && dirty && iconType === 'close' && (
        <div
          tabIndex={0}
          role="button"
          onClick={resetHandler}
          onKeyPress={resetHandler}
          className="chips__reset"
        >
          <CloseIconChip fill={isDark ? palette.white : palette.cocoaBrown} />
        </div>
      )}
    </label>
  );
};

NewChips.propTypes = {
  icon: PropTypes.func,
  id: PropTypes.string,
  cypress: PropTypes.string,
  stat: PropTypes.string,
  text: PropTypes.string,
  dirty: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  name: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  iconSize: PropTypes.number,
  borderRadius: PropTypes.string,
  resetHandler: PropTypes.func,
  type: PropTypes.oneOf(['button', 'radio', 'checkbox']),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array
  ]),
  storedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array
  ]),
  color: PropTypes.string,
  iconActiveColor: PropTypes.string,
  iconNeedColorChange: PropTypes.bool,
  activeIcon: PropTypes.func,
  isDark: PropTypes.bool,
  isTextPadding: PropTypes.bool,
  iconType: PropTypes.oneOf(['angle', 'close']),
  useDirtyAsActive: PropTypes.bool
};

export default NewChips;
